<template>
    <authed-page>
        <div class="ast-app" v-if="ready" :class="{'lighting':isLighting}">
            <div class="nav-bar">
                
                <div class="logo-container">
                    <router-link class="logo" to="/ast/xcx/base">颜创AI数智美学私域系统</router-link>
                </div>
                <div class="nav-body">
                    <trusteeship/>
                </div>
                <div class="nav-right">
                    <template v-if="info">
                        <div class="welcome">
                            欢迎👏
                            <span v-if="app">，{{ app.nickname }}</span>
                        </div>
                        
                        <a-dropdown>
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                
                                <template v-if="astMember">
                                    <div class="user-info">
                                        <div class="avatar">
                                            <img :src="astMember.avatar" alt="">
                                        </div>
                                        <span>{{ astMember.mark_name || astMember.name }}</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="user-info">
                                        <div class="avatar">
                                            <img :src="info.setting.avatar" alt="">
                                        </div>
                                        <span>{{ info.setting.name }}</span>
                                    </div>
                                </template>
                            
                            </a>
                            <a-menu slot="overlay">
                                <a-menu-item>
                                    <l-a href="/ast/deck" class="menu-item">开始搭配</l-a>
                                </a-menu-item>
                                <a-menu-item v-if="hasMoreApp">
                                    <a @click="switchApp" class="menu-item">切换小程序</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <div @click="logOut" class="menu-item">退出登录</div>
                                </a-menu-item>
                            
                            </a-menu>
                        </a-dropdown>
                        
                        <a-dropdown>
                            <div class="service-info">
                                <div class="service-icon"></div>
                            </div>
                            <div class="service-qrcode" slot="overlay">
                                <div>在线客服（无须添加好友）</div>
                                <div class="img-box">
                                    <img src="/assets/images/ser-qrcode.png" alt="">
                                </div>
                                <div>工作时间 10:00~18:30</div>
                            </div>
                        </a-dropdown>
                        
                        <a-dropdown>
                            <div class="service-info">
                                <router-link to="/ast/helpers">帮助中心</router-link>
                            </div>
                        </a-dropdown>
                    
                    </template>
                    <template v-else-if=" info === false ">
                        <router-link class="start-dps" to="/login">登录</router-link>
                    </template>
                </div>
            
            
            </div>
            
            <div class="main-content">
                
                <div class="left-nav-container" v-if="!hideMenu">
                    <ast-menu :current="currentMenu" :menus="items"/>
                </div>
                
                <div class="right-content" :class="{'right-menu-hided':hideMenu}">
                    <transition name="slide-fade">
                        <router-view ref="contentView" :app="app"/>
                    </transition>
                </div>
                <a-back-top class="back-top"/>
            </div>
            <div class="ba">蜀ICP备2021007621号-2</div>
        
        </div>
        <div v-else class="loading-block">
            <loader/>
        </div>
    </authed-page>
</template>

<script>

import Vue from 'vue';
import auth from "../auth";
import AuthedPage from "./AuthedPage";
import api from "../repo/api";
import Loader from "../components/Loader";
import LA from "../components/LA";
import Trusteeship from "@/layout/Sub/Trusteeship.vue";
import AstMenu from "@/views/AST/Xcx/Com/AstMenu.vue";

export default {
    name: "AstPage",
    components: {AstMenu, Trusteeship, LA, Loader, AuthedPage},
    props: {
        validateGender: {
            type: Boolean,
            default() {
                return true
            }
        },
        navMode: {
            type: String,
            default() {
                return 'normal';
            }
        },
        lighting: Boolean,
        hideItems: Boolean,
        current: {
            type: String,
        },
    },
    data() {
        return {
            ready: false,
            app: {},
            items: [],
            info: null,
            isLighting: this.lighting,
            gender: auth.gender(),
            uri: '',
            hasMoreApp: auth.appId() && (auth.appLength() > 1),
            astMember: null,
            currentMenu: "",
            hideMenu: false,
        }
    },
    mounted() {
        this.init()
    },
    watch: {
        navTop() {
            this.top = this.navTop
        },
        '$route.path'() {
            this.matchRoute()
        }
    },
    methods: {
        matchRoute() {
            setTimeout(() => {
                
                if (this.$refs['contentView']) {
                    this.currentMenu = this.$refs['contentView'].routeMatch || this.$route.path
                    
                    this.hideMenu = this.$refs['contentView'].hideMenu || false
                    
                } else {
                    this.$router.push('/ast/xcx/base')
                }
                
                
            }, 50)
        },
        switchApp() {
            auth.appId(0)
            this.$router.push('/login')
        },
        navs() {
            console.log(this.app.modules);
            return [
                {
                    'name': '小程序设置',
                    'icons': {
                        'normal': '/assets/icons/xcx-2.png',
                        'highlight': '/assets/icons/xcx-1.png',
                    },
                    'children': [
                        {
                            'name': '基础设置',
                            'route': '/ast/xcx/base',
                        },
                        {
                            'name': '功能设置',
                            'route': '/ast/xcx/item',
                        },
                        {
                            'name': "美学工具",
                            'children': [
                                {
                                    'name': '口红试色',
                                    'route': '/ast/config/tools/lipstick',
                                    'hide': (!this.app.modules.mx_lipstick),
                                },
                                {
                                    'name': '服装试色',
                                    'route': '/ast/config/tools/cloth',
                                    'hide': (!this.app.modules.mx_cloth),
                                },
                                {
                                    'name': '色彩诊断',
                                    'route': '/ast/config/tools/color',
                                    'hide': (!this.app.modules.mx_color),
                                }
                            ],
                        },
                        {
                            'name': '推送设置',
                            'route': '/ast/xcx/push',
                        },
                        {
                            'name': '用量查询',
                            'route': '/ast/xcx/balance',
                        },
                        {
                            'name': '托管设置',
                            'route': '/ast/xcx/trusteeship',
                            'roles': ['admin'],
                        },
                        {
                            'name': 'API接口',
                            'hide': (!this.app.modules.yc_api),
                            'route': '/ast/xcx/open',
                            'roles': ['owner'],
                        },
                        {
                            'name': '管理员设置',
                            'route': '/ast/xcx/admin',
                        },
                        {
                            'name': '支付申请',
                            'route': '/ast/pay/apply',
                            'hide': (this.app.sub_mchid) || (this.app.model === 'person'),
                        }
                    ],
                },
                {
                    'name': '运营设置',
                    'icons': {
                        'normal': '/assets/icons/yy-2.png',
                        'highlight': '/assets/icons/yy-1.png',
                    },
                    'children': [
                        {
                            'name': '开通在线视频',
                            'route': '/ast/serve/video/open',
                            'hide': (this.$app.modules.course_video)
                        },
                        {
                            'name': '视频素材管理',
                            'route': '/ast/serve/video',
                            'hide': (!this.$app.modules.course_video)
                        },
                        {
                            'name': '会员类型设置',
                            'route': '/ast/serve/level'
                        },
                        {
                            'name': '服务介绍设置',
                            'route': '/ast/serve/ser'
                        },
                        {
                            'name': '课程介绍设置',
                            'route': '/ast/serve/cla'
                        },
                        {
                            'name': '活动介绍设置',
                            'route': '/ast/serve/act'
                        },
                        {
                            'name': '服务类型设置',
                            'route': '/ast/serve/type',
                        },
                        {
                            'name': '案例分享设置',
                            'route': '/ast/cases',
                        },
                        {
                            'name': '问卷调查设置',
                            'route': '/ast/qas',
                        }
                        ,{
                            'name': '签署合同设置',
                            'route': '/ast/contract',
                        }
                    ],
                },
                {
                    'name': '会员列表',
                    'icons': {
                        'normal': '/assets/icons/hy-2.png',
                        'highlight': '/assets/icons/hy-1.png',
                    },
                    'children': [
                        {
                            'name': '会员列表',
                            'route': '/ast/members',
                        },
                        {
                            'name': '报名列表',
                            'route': '/ast/members/applies',
                        },
                        {
                            'name': '订单列表',
                            'route': '/ast/members/orders',
                        },
                        {
                            'name': '形象诊断书',
                            'route': '/ast/members/reports',
                        },
                        {
                            'name': '推荐列表',
                            'route': '/ast/shop/recommend',
                        }
                    ],
                },
                {
                    'name': '纸质报告',
                    'icons': {
                        'normal': '/assets/icons/zzbg-1.png',
                        'highlight': '/assets/icons/zzbg-1.png',
                    },
                    'children': [
                        {
                            'name': '机构基础信息',
                            'route': '/ast/paper/basic',
                        },
                        {
                            'name': '实体报告订单',
                            'route': '/ast/paper/orders',
                        },
                        {
                            'name': '报告兑换码',
                            'route': '/ast/paper/codes',
                        }
                    ]
                },
                {
                    'name': '电商搭配',
                    'icons': {
                        'normal': '/assets/icons/dp-2.png',
                        'highlight': '/assets/icons/dp-1.png',
                    },
                    'children': [
                        {
                            'name': '开始搭配',
                            'route': `/ast/decks`,
                        },
                        {
                            'name': '选品搭配',
                            'route': '/ast/goods',
                        },
                        {
                            'name': '电商自选库',
                            'route': '/ast/goods/my',
                        }
                    ],
                },
                {
                    'name': '在线商店',
                    'hide': (!this.app.open_store),
                    'children': [
                        {
                            'name': '店铺管理',
                            'route': '/ast/shop',
                        },
                        {
                            'name': '开始搭配',
                            'route': `/ast/decks`,
                        },
                        {
                            'name': '搭配列表',
                            'children': [
                                {
                                    'name': '展示中',
                                    'route': `/ast/shop/scheme/show`,
                                },
                                {
                                    'name': '下架中',
                                    'route': `/ast/shop/scheme/hide`,
                                },
                                {
                                    'name': '回收站',
                                    'route': `/ast/shop/scheme/delete`,
                                }
                            ]
                        },
                        {
                            'name': '单品列表',
                            'route': '/ast/shop/goods',
                        },
                        {
                            'name': '商品上传',
                            'route': '/ast/shop/goods/review',
                            'hide': (this.app.open_store !== 1)
                        },
                        {
                            'name': '数据同步',
                            'route': '/ast/shop/goods/sync',
                            'hide': (this.app.open_store !== 1)
                        },
                        {
                            'name': '展品列表',
                            'route': '/ast/shop/exhibits',
                        },
                    ],
                    'icons': {
                        'normal': '/assets/icons/sd-2.png',
                        'highlight': '/assets/icons/sd-1.png',
                    },
                },
                {
                    'name': '收银台',
                    'hide': (!this.app.modules.cashier) || (this.app.model === 'person'),
                    'children': [
                        {
                            'name': '收银订单',
                            'route': '/ast/shop/cashier',
                            'roles': ['admin'],
                        },
                        {
                            'name': '记账列表',
                            'route': '/ast/shop/bill',
                            'roles': ['admin'],
                        },
                        {
                            'name': "自动分销",
                            'route': '/ast/shop/share-setting',
                            'roles': ['admin'],
                        }
                    ],
                    'icons': {
                        'normal': '/assets/icons/cash.png',
                        'highlight': '/assets/icons/cash.png',
                    },
                },
                {
                    'name': '视频号登录',
                    'roles': ['admin'],
                    'target': '_blank',
                    'route': 'https://channels.weixin.qq.com/platform/login',
                    'icons': {
                        'normal': '/assets/icons/sph-2.png',
                        'highlight': '/assets/icons/sph-1.png',
                    },
                }
            ];
        },
        init() {
            auth.check((res) => {
                this.info = res ? auth.userInfo() : false
                this.getAppStatus();
            })
        },
        getAppStatus() {
            
            api.get('/ast-app/status', (data) => {
                
                if ((!data.data) || (!data.data.status)) {
                    
                    this.$router.push('/login')
                    
                    return
                }
                
                if (!data.data.register_finished && this.$route.path !== '/ast/pay/apply') {
                    return this.$router.push('/ast/reg');
                }
                
                let app = data.data
                
                app.roles = [app.master_type]
                
                if (data.data.is_owner) app.roles.push('owner')
                
                this.app = app
                this.ready = true
                if (app.payment.currency == 'CNY') {
                    app.payment.symbol = null;
                }
                Vue.prototype.$app = app;
                
                this.astMember = data.data.ast_member;
                
                if (auth.userInfo().id === 589 || auth.userInfo().id === 590) {
                    app.roles.push('owner')
                }
                
                auth.appId(app.id)
                
                this.initNav()
            })
        },
        initNav() {
            let items = this.navs()
            
            this.items = []
            
            items.forEach(nav => {
                
                if (nav.dev && (!(this.$app.id === 9 || this.$app.id === 15))) return;
                
                if (!this.premCheck(nav)) return;
                
                if (nav.hide) return;
                
                if (!nav.children) {
                    
                    this.items.push(nav)
                    
                    return;
                }
                
                let children = []
                
                nav.children.forEach(child => {
                    
                    if (!this.premCheck(child)) return;
                    
                    if (child.hide) return;
                    
                    if (child.dev && (!(this.$app.id === 9 || this.$app.id === 15))) return;
                    
                    if (!child.children) {
                        
                        children.push(child)
                        return;
                    }
                    
                    let subList = [];
                    
                    child.children.forEach(subChild => {
                        
                        if (!this.premCheck(subChild)) return;
                        
                        if (subChild.hide) return;
                        
                        if (subChild.dev && (!(this.$app.id === 9 || this.$app.id === 15))) return;
                        
                        
                        subList.push(subChild)
                        
                    })
                    
                    if (!subList.length) return;
                    
                    child.children = subList
                    
                    children.push(child)
                })
                
                if (!children.length) return;
                
                nav.children = children
                
                this.items.push(nav)
            })
            
            this.matchRoute()
        },
        logOut() {
            if (!confirm('确定要退出登录吗？')) return
            
            auth.loginOut()
            
            location.href = '/'
        },
        premCheck(item) {
            
            if (!this.app.roles) return false;
            
            if (!item.roles) return true;
            
            if (this.$app.roles.indexOf('owner') >= 0) return true;
            
            for (const rolesKey in item.roles) {
                
                let row = item.roles[rolesKey]
                
                if (this.$app.roles.indexOf(row) >= 0) return true;
                
            }
            
            return false;
        }
    }
}
</script>

<style lang="less" scoped>
.main-content {
    min-height: 100vh;
    padding-top: 64px;
    display: flex;
    
    .right-content {
        padding-top: 64px;
        padding-right: 32px;
        padding-left: 368px;
        flex: 1;
        
    }
    
    .right-menu-hided {
        padding-left: 64px;
        width: 1200px;
        margin: 0 auto;
    }
    
    .left-nav-container {
        width: 335px;
        background-color: white;
        position: fixed;
        z-index: 101;
    }
    
    .aff {
        background-color: white;
    }
    
    .item-title {
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        line-height: 22px;
        padding-top: 13px;
        padding-bottom: 13px;
        display: flex;
        align-items: center;
    }
    
    .nav-item {
        &.active, &.expand {
            .item-title {
                color: #333333;
            }
        }
        
        &.active .item-title {
            background: #F5F5F5;
        }
        
        width: 100%;
        position: relative;
        padding-top: 19px;
        padding-bottom: 19px;
        padding-left: 25px;
        overflow: hidden;
        
        .item-icon {
            width: 52px;
            display: inline-block;
            text-align: center;
            line-height: 0;
            
            img {
                width: 24px;
                
            }
            
            .highlight {
                display: none;
            }
        }
        
        &.active {
            .item-icon .normal {
                display: none;
            }
            
            .item-icon .highlight {
                display: inline;
            }
        }
        
        &:first-child {
            padding-top: 0;
            
            &:before {
                display: none;
            }
        }
        
        &:before {
            content: ' ';
            height: 1px;
            background: #EEEEEE;
            width: 100%;
            position: absolute;
            top: 0;
            left: 77px;
        }
        
        .item-children {
            
            padding-top: 10px;
            
            .children-title {
                display: block;
                margin-bottom: 8px;
                padding: 8px 8px 8px 52px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                line-height: 22px;
                
                &:last-child {
                    margin-bottom: 5px;
                }
                
                &.active {
                    
                    background: #F5F5F5;
                    color: #333333;
                }
            }
        }
    }
}

.logo {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
}

.nav-items {
    display: flex;
}

.user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: 100%;
    
    span {
        color: rgba(0, 0, 0, 0.65);
    }
    
    
    z-index: 2;
}

.nav-item {
    color: rgba(51, 51, 51, 1);
    margin-right: 64px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    
    &:last-child {
        margin-right: 0;
    }
    
    &:hover {
        color: black;
    }
    
    &.active:after {
        content: ' ';
        width: 4px;
        height: 4px;
        position: absolute;
        bottom: -8px;
        left: 50%;
        background: #333333;
        border-radius: 50%;
        margin-left: -2px;
    }
}

.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    padding-left: 64px;
    padding-right: 64px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    font-size: 14px;
    background-color: white;
    z-index: 102;
    
    transition: all .3s;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.avatar {
    width: 24px;
    border-radius: 24px;
    overflow: hidden;
    margin-right: 8px;
    border: 1px solid #dddddd;
    
    img {
        width: 24px;
        height: 24px;
    }
}

.nav-right {
    min-width: 260px;
    display: flex;
    justify-content: right;
}

@media screen and(max-width: 1100px) {
    .nav-right .welcome {
        display: none;
    }
}

.nav-gender {
    top: 24px;
    
    .nav-items {
        display: none;
    }
}

.lighting {
    
    .nav-bar {
        background-color: transparent;
        box-shadow: none;
    }
    
    .gender {
        color: #FFFFFF;
    }
    
    .logo {
        color: #FFFFFF;
    }
    
    .user-info span {
        color: #FFFFFF;
    }
    
    .nav-item {
        &.active:after {
            background: white;
        }
    }
    
    .nav-items a {
        color: #FFFFFF;
    }
    
    .start-dps {
        color: #FFFFFF;
        border-color: #FFFFFF;
    }
}

.gender {
    margin-left: 55px;
    text-decoration: none;
    
    cursor: pointer;
    
    .iconfont {
        font-size: 12px;
        margin-left: 8px;
    }
}

.logo-container, .nav-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: 100%;
}

.start-dps {
    margin-left: 25px;
    
    border: 1px solid #333333;
    width: 96px;
    height: 32px;
    text-decoration: none;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    color: #333333;
    font-weight: 400;
}

.arrow-icon {
    img {
        width: 14px;
    }
    
    position: absolute;
    right: 0;
}

.ba {
    width: 100%;
    height: 64px;
    text-align: center;
    line-height: 64px;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    
    border-top: 1px solid #EEEEEE;
    position: relative;
    z-index: 99;
    background-color: white;
}

.welcome {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-right: 40px;
}

.service-info {
    padding-left: 20px;
    border-left: 1px solid #EEEEEE;
    margin-left: 20px;
    
    a {
        color: #333333;
    }
}

.service-icon {
    width: 24px;
    height: 24px;
    background-color: #333333;
    border-radius: 24px;
    background-image: url("/assets/icons/ser-icon.png");
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
}


.service-qrcode {
    width: 176px;
    height: 222px;
    background: #FFFFFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    border: 1px solid #EEEEEE;
    padding: 16px 0 16px 20px;
    
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 17px;
    
    .img-box {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 136px;
        height: 136px;
    }
    
    img {
        width: 100%;
    }
}

.ast-app {
    min-width: 1296px;
}

.back-top {
    z-index: 99999;
}

</style>